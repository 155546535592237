import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { PageContentSize } from '@array-app/shared/types';

@Component({
	standalone: true,
	selector: 'app-page-content-navigation',
	imports: [CommonModule],
	templateUrl: 'page-content-navigation.component.html',
	styleUrls: ['page-content-navigation.component.scss']
})
export class PageContentNavigationComponent {
	@Input()
	@HostBinding('class')
	size: PageContentSize = 'full';
}
