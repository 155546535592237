import {
	Directive,
	ElementRef,
	forwardRef,
	Host,
	HostListener
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HexColorInputComponent } from '../components';
import { ValueAccessor } from './value-accessor';

@Directive({
	standalone: true,
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'app-hex-color-input[formControlName]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => HexColorInputValueAccessorDirective),
			multi: true
		}
	]
})
export class HexColorInputValueAccessorDirective extends ValueAccessor<string> {
	constructor(
		@Host() protected override host: HexColorInputComponent,
		protected override readonly el: ElementRef
	) {
		super(host, el);
	}

	@HostListener('valueChange', ['$event'])
	_handleChangeEvent(value: string) {
		this.handleChangeEvent(this.el.nativeElement, value);
	}

	override setDisabledState(isDisabled: boolean): void {
		this.el.nativeElement.disabled = isDisabled;
		const inputEl = (this.el.nativeElement as HTMLElement).querySelector(
			'input'
		);
		if (inputEl) {
			inputEl.disabled = isDisabled;
		}
		const control = this.el.nativeElement as HTMLElement;
		if (isDisabled) {
			control.setAttribute('disabled', 'true');
		} else {
			control.removeAttribute('disabled');
		}
	}
}
