import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-password-validator-row',
	imports: [CommonModule],
	templateUrl: 'password-validator-row.component.html',
	styleUrls: ['password-validator-row.component.scss']
})
export class PasswordValidatorRowComponent {
	@HostBinding('class.success')
	@Input()
	success = false;
}
