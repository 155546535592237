import { CommonModule } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';
import {
	LoadingSpinnerComponent,
	RelativeTimestampPipe,
	TextBadgeComponent
} from '@array-app/frontend/common';
import { Notification } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';

@Component({
	standalone: true,
	selector: 'app-notification-item',
	imports: [
		CommonModule,
		RelativeTimestampPipe,
		TextBadgeComponent,
		LoadingSpinnerComponent,
		TranslateModule,
		QuillModule
	],
	templateUrl: 'notification-item.component.html',
	styleUrls: ['notification-item.component.scss']
})
export class NotificationItemComponent {
	@Input() notification!: Notification;

	@Input() hideActions = false;
	/**
	 * `true` if there is a request active pertaining to an action in this item
	 */
	@Input() busy = false;

	@Output() markAsRead = new EventEmitter<void>();
	@Output() delete = new EventEmitter<void>();

	@HostBinding('class.unread')
	get unread() {
		return !this.notification.readAt;
	}

	onMarkAsRead() {
		this.markAsRead.emit();
	}

	onDelete() {
		if (this.notification.deletedAt) {
			return;
		}
		this.delete.emit();
	}
}
