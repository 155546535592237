import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { PageContentSize } from '@array-app/shared/types';

@Component({
	standalone: true,
	selector: 'app-page-content',
	imports: [CommonModule],
	templateUrl: 'page-content.component.html',
	styleUrls: ['page-content.component.scss']
})
export class PageContentComponent {
	@Input()
	@HostBinding('class')
	size: PageContentSize = 'full';

	@Input()
	@HostBinding('class.has-header')
	hasHeader = true;
}
