import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HexColorComponent } from '@array-app/frontend/common';
import { ColorPickerModule } from 'ngx-color-picker';

@Component({
	standalone: true,
	selector: 'app-hex-color-input',
	imports: [HexColorComponent, ColorPickerModule],
	templateUrl: 'hex-color-input.component.html',
	styleUrls: ['hex-color-input.component.scss']
})
export class HexColorInputComponent {
	/**
	 * The initial value to set the color picker with
	 * @defaults #ffffff - white
	 */
	@Input() value = '#ffffff';

	@Output() valueChange = new EventEmitter<string | null>();

	onPickerChange() {
		this.valueChange.emit(this.value);
	}

	onValueChange({ value }: any) {
		if (this.value != value) {
			this.value = value;
		}
		this.valueChange.emit(this.value);
	}
}
