import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RouteService implements OnDestroy {
	previousUrl!: string;
	currentUrl!: string;

	readonly destroy$ = new ReplaySubject<void>();

	constructor(private readonly router: Router) {
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;
			}
		});
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
