<h1 mat-dialog-title>{{ 'notifications.header' | translate }}</h1>

<div mat-dialog-content>
	<app-notification-item
		[hideActions]="true"
		[notification]="data.notification"
	></app-notification-item>
</div>

<div mat-dialog-actions>
	<button class="secondary icon" (click)="onClose()">
		<i class="fa-solid fa-xmark"></i>
		{{ 'generic.close' | translate }}
	</button>

	<button
		*ngIf="!data.notification.readAt"
		[class.loading]="busy"
		class="secondary icon"
		[disabled]="busy"
		(click)="onMarkAsRead()"
	>
		<i class="fa-solid fa-check-double"></i>
		{{ 'notifications.mark-read' | translate }}
		<app-loading-spinner type="chromatic"></app-loading-spinner>
	</button>

	<button
		*ngIf="data.notification.readAt && !data.notification.deletedAt"
		[class.loading]="busy"
		class="danger icon"
		[disabled]="busy"
		(click)="onDelete()"
	>
		<i class="fa-solid fa-xmark"></i>
		{{ 'generic.delete' | translate }}
		<app-loading-spinner type="chromatic"></app-loading-spinner>
	</button>
</div>
