import {
	Directive,
	ElementRef,
	forwardRef,
	Host,
	HostListener
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploadComponent } from '@array-app/frontend/files';
import { ValueAccessor } from './value-accessor';

@Directive({
	standalone: true,
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'app-file-upload[formControlName]',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FileUploadValueAccessorDirective),
			multi: true
		}
	]
})
export class FileUploadValueAccessorDirective extends ValueAccessor<File> {
	constructor(
		@Host() protected override host: FileUploadComponent,
		protected override readonly el: ElementRef
	) {
		super(host, el);
	}

	@HostListener('valueChange', ['$event'])
	_handleChangeEvent(value: File) {
		this.handleChangeEvent(this.el.nativeElement, value);
	}
}
