<div class="info">
	<p class="margin-top-0 small text-tertiary">
		{{ notification.createdAt | relativeTimestamp }}
	</p>
	<h4 class="margin-0 margin-bottom-1">{{ notification.title }}</h4>
	<quill-view-html [content]="notification.message"></quill-view-html>
</div>
<div *ngIf="!hideActions" class="actions">
	<button
		*ngIf="!notification.readAt"
		class="secondary icon"
		[class.loading]="busy"
		(click)="onMarkAsRead()"
		[disabled]="busy"
	>
		<i class="fa-solid fa-check-double"></i>
		{{ 'notifications.mark-read' | translate }}
		<app-loading-spinner></app-loading-spinner>
	</button>

	<button
		*ngIf="notification.readAt && !notification.deletedAt"
		class="secondary icon"
		[class.loading]="busy"
		(click)="onDelete()"
		[disabled]="busy"
	>
		<i class="fa-solid fa-xmark"></i>
		{{ 'generic.delete' | translate }}
		<app-loading-spinner></app-loading-spinner>
	</button>
</div>

<div *ngIf="notification.deletedAt && hideActions" class="deleted">
	<app-text-badge type="danger">
		{{ 'generic.deleted' | translate }}
	</app-text-badge>
</div>
