import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PageContentSize } from '@array-app/shared/types';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
	standalone: true,
	selector: 'app-page-header',
	imports: [CommonModule, RouterModule, BreadcrumbsComponent],
	templateUrl: 'page-header.component.html',
	styleUrls: ['page-header.component.scss']
})
export class PageHeaderComponent {
	@Input() backUrl: string | null = null;

	@Input() breadcrumbs = true;

	@Input()
	@HostBinding('class')
	size: PageContentSize = 'full';
}
