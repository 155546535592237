import {
	Directive,
	ElementRef,
	Host,
	HostListener,
	Input
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Directive()
export class ValueAccessor<T> implements ControlValueAccessor {
	@Input() currentValue!: T;
	protected lastValue!: T;
	protected onChange: (value: T) => void = () => {
		/**/
	};
	private onTouched: () => void = () => {
		/**/
	};

	constructor(
		@Host() protected readonly host: any,
		protected readonly el: ElementRef
	) {}

	writeValue(value: T) {
		this.currentValue = this.host.value = this.el.nativeElement.value = this.lastValue = value;
	}

	handleChangeEvent(el: HTMLElement, value: T, skipElementCheck = false) {
		if (el === this.el.nativeElement || skipElementCheck) {
			if (value !== this.lastValue) {
				this.lastValue = value;
				this.onChange(value);
			}
		}
	}

	@HostListener('blur', ['$event.target'])
	_handleBlurEvent(el: ElementRef) {
		if (el === this.el.nativeElement) {
			this.onTouched();
		}
	}

	registerOnChange(fn: (value: T) => void) {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.el.nativeElement.disabled = isDisabled;
		const control = this.el.nativeElement as HTMLElement;
		if (isDisabled) {
			control.setAttribute('disabled', 'true');
		} else {
			control.removeAttribute('disabled');
		}
	}
}
