import { Language } from '@array-app/shared/types';

export const SupportedLanguages: Language[] = [
	// {
	// 	name: 'Arabic',
	// 	code: 'ar'
	// },
	// {
	// 	name: 'Danish',
	// 	code: 'da'
	// },
	{
		name: 'English',
		code: 'en'
	},
	{
		name: 'French',
		code: 'fr'
	},
	{
		name: 'German',
		code: 'de'
	},
	{
		name: 'Italian',
		code: 'it'
	},
	{
		name: 'Japanese',
		code: 'ja'
	},
	{
		name: 'Latin',
		code: 'la'
	},
	{
		name: 'Russian',
		code: 'ru'
	},
	{
		name: 'Spanish',
		code: 'es'
	}
];
export const DefaultLanguage = SupportedLanguages[0];
