import { Component, HostBinding, Input } from '@angular/core';

export type AlertType = 'success' | 'danger' | 'info' | 'warning';
export type AlertIconMap = {
	type: AlertType;
	icon: string;
};

@Component({
	standalone: true,
	selector: 'app-alert-banner',
	templateUrl: 'alert-banner.component.html',
	styleUrls: ['alert-banner.component.scss']
})
export class AlertBannerComponent {
	/**
	 * The type of alert banner to render. Each type will have a different icon
	 * and color scheme
	 */
	@HostBinding('class')
	@Input()
	type: AlertType = 'warning';

	iconMap: Map<AlertType, string> = new Map([
		['info', 'fa-solid fa-circle-info'],
		['success', 'fa-solid fa-circle-check'],
		['danger', 'fa-solid fa-circle-exclamation'],
		['warning', 'fa-solid fa-triangle-exclamation']
	]);
}
