import { Permissions, Role } from '@array-app/shared/types';

export type MenuItem = {
	name: string;
	icon: string;
	url: string;
	permissions?: (keyof Permissions)[];
};

export type MenuGroup = {
	roles: Role[];
	items: MenuItem[];
};

export const MenuGroups: MenuGroup[] = [
	{
		roles: ['user', 'admin', 'owner', 'super'],
		items: [
			{
				name: 'route.title.dashboard',
				icon: 'fa-solid fa-gauge-high',
				url: '/app/dashboard'
			}
			// {
			// 	name: 'route.title.external-links',
			// 	icon: 'fa-solid fa-link',
			// 	url: '/app/external-links',
			// 	permissions: ['canShareProducts']
			// }
			// {
			// 	name: 'route.title.analytics',
			// 	icon: 'fa-solid fa-chart-line',
			// 	url: '/app/analytics'
			// }
		]
	},
	{
		roles: ['admin', 'owner', 'super'],
		items: [
			{
				name: 'route.title.products',
				icon: 'fa-solid fa-cube',
				url: '/app/products'
			},
			// {
			// 	name: 'route.title.categories',
			// 	icon: 'fa-solid fa-cubes',
			// 	url: '/app/categories'
			// },
			{
				name: 'route.title.users',
				icon: 'fa-solid fa-user-group',
				url: '/app/users'
			},
			{
				name: 'route.title.groups',
				icon: 'fa-solid fa-users',
				url: '/app/groups'
			},
			{
				name: 'route.title.tags',
				icon: 'fa-solid fa-tag',
				url: '/app/tags'
			},
			{
				name: 'route.title.reports',
				icon: 'fa-solid fa-file-lines',
				url: '/app/reports'
			}
		]
	},
	{
		roles: ['owner', 'super'],
		items: [
			{
				name: 'route.title.organization',
				icon: 'fa-solid fa-building',
				url: '/app/organization'
			}
			// {
			// 	name: 'route.title.settings',
			// 	icon: 'fa-solid fa-gear',
			// 	url: '/app/settings'
			// }
		]
	},
	{
		roles: ['super'],
		items: [
			{
				name: 'route.title.organizations',
				icon: 'fa-solid fa-city',
				url: '/app/organizations'
			}
		]
	}
];
