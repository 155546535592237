import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
	CollectionReference,
	Firestore,
	Timestamp,
	collection,
	orderBy,
	query,
	where
} from '@angular/fire/firestore';
import { AuthService } from '@array-app/frontend/authentication';
import { BaseApiService } from '@array-app/frontend/common';
import { Notification } from '@array-app/shared/types';

@Injectable({
	providedIn: 'root'
})
export class NotificationApiService extends BaseApiService<Notification> {
	constructor(
		protected override readonly firestore: Firestore,
		protected override readonly authService: AuthService,
		protected override readonly injector: Injector,
		private readonly http: HttpClient
	) {
		super(firestore, authService, injector, 'notifications');
	}

	/**
	 * Initializes the firestore service
	 */
	override initialize(org: string) {
		if (this.collectionName) {
			const userId = this.authService.user$.value?.id;
			if (org && userId) {
				this.organizationId = org;
				this.collection = collection(
					this.firestore,
					`organizations/${org}/users/${userId}/${this.collectionName}`
				) as CollectionReference<Notification, Notification>;

				this.collectionQuery = query(
					this.collection,
					orderBy('createdAt', 'desc'),
					where('deletedAt', '==', null)
				);
			} else {
				console.warn('No organization or user id was provided');
			}
		}
	}

	/**
	 * Creates a new notification tied to the provided user id with the given data
	 *
	 * @param data the data used to create the new notification
	 * @param userId the user id to associate the notification to
	 * @returns observable of the http request
	 */
	override create$(data: Partial<Notification & { userId?: string }>) {
		const userId = data.userId;
		delete data.userId;
		return this.http.post<Notification>(
			`${this.url}/users/${userId}/notifications`,
			data
		);
	}

	/**
	 * Marks the corresponding notification as being read for a different
	 * visual cue.
	 *
	 * @param id the id of the notification to mark
	 * @param readAt the timestamp of when it was marked as read
	 * @returns empty object
	 */
	markAsRead(id: string, readAt: Timestamp) {
		try {
			return this.update({ id, readAt });
		} catch (error) {
			console.error(error);
			throw new Error('There was an issue updating the notification');
		}
	}
}
