import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { StringMatchRegExpPipe } from '@array-app/frontend/common';
import {
	RegExpEightCharacters,
	RegExpOneLetter,
	RegExpOneNumber,
	RegExpOneSpecialCharacter
} from '@array-app/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordValidatorRowComponent } from './password-validator-row/password-validator-row.component';

@Component({
	standalone: true,
	selector: 'app-password-validator',
	imports: [
		PasswordValidatorRowComponent,
		CommonModule,
		StringMatchRegExpPipe,
		TranslateModule
	],
	templateUrl: 'password-validator.component.html',
	styleUrls: ['password-validator.component.scss']
})
export class PasswordValidatorComponent {
	@Input()
	password!: string;

	readonly validations = [
		{
			label: 'auth.password.eight-characters',
			regex: RegExpEightCharacters
		},
		{
			label: 'auth.password.one-letter',
			regex: RegExpOneLetter
		},
		{
			label: 'auth.password.one-number',
			regex: RegExpOneNumber
		},
		{
			label: 'auth.password.one-special',
			regex: RegExpOneSpecialCharacter
		}
	];
}
