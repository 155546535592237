<ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
	<ng-container *ngIf="breadcrumb?.url && breadcrumb.text && !last">
		<a
			class="secondary"
			[title]="breadcrumb.text | translate"
			[routerLink]="[breadcrumb?.url]"
		>
			{{ breadcrumb.text | translate }}
		</a>
	</ng-container>
	<ng-container *ngIf="(!breadcrumb?.url || last) && breadcrumb.text">
		<p class="text-secondary small" [title]="breadcrumb.text | translate">
			{{ breadcrumb.text | translate }}
		</p>
	</ng-container>
	<ng-container *ngIf="!last">
		<i class="fa-solid fa-chevron-right"></i>
	</ng-container>
</ng-container>
