import { Component, Injector, OnInit } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@array-app/frontend/authentication';
import { DestroyService, VERSION_TOKEN } from '@array-app/frontend/common';
import {
	NotificationApiService,
	NotificationDialogComponent
} from '@array-app/frontend/notifications';
import { SwitchOrganizationDialogComponent } from '@array-app/frontend/organizations';
import {
	DialogAction,
	DialogRole,
	DisplayTheme,
	Notification,
	Organization,
	User
} from '@array-app/shared/types';
import { getDisplayTheme } from '@array-app/shared/utils';
import { take, takeUntil } from 'rxjs';
import { MenuGroups } from '../../data/menu-groups.const';

@Component({
	selector: 'app-nav-template',
	templateUrl: 'nav-template.component.html',
	styleUrls: ['nav-template.component.scss'],
	providers: [DestroyService]
})
export class NavTemplateComponent implements OnInit {
	expanded = true;

	menuGroups = MenuGroups;

	user!: User;

	organization!: Organization;
	canSwitchOrganization = false;

	/**
	 * Array of all notifications corresponding to this user
	 */
	notifications: Notification[] = [];

	/**
	 * `true` if the notifications are loading in
	 */
	notificationsLoading = false;

	/**
	 * A set of notification requests that are currently active
	 */
	notificationRequests = new Set<string>();

	/**
	 * The number of notifications that have been unread
	 */
	unreadCount = 0;

	/**
	 * The max amount of notifications that can be displayed in the indicator
	 */
	readonly maxNotificationsCount = 99;
	readonly version = this.injector.get(VERSION_TOKEN);

	get displayTheme(): DisplayTheme {
		return getDisplayTheme();
	}

	constructor(
		public readonly authService: AuthService,
		private readonly router: Router,
		private readonly dialog: MatDialog,
		private readonly notificationService: NotificationApiService,
		private readonly route: ActivatedRoute,
		private readonly destroy$: DestroyService,
		private readonly injector: Injector
	) {}

	ngOnInit() {
		this.expanded = localStorage.getItem('expanded') === 'true';
		this.setupNotifications();

		this.authService.user$
			.pipe(takeUntil(this.destroy$))
			.subscribe((user) => {
				if (user) {
					this.user = user;
				}
			});

		this.authService.authUser$
			.pipe(takeUntil(this.destroy$))
			.subscribe((authUser) => {
				if (authUser) {
					this.canSwitchOrganization =
						authUser.users.filter((user) => !user.deletedAt)
							.length > 1;
				}
			});

		this.authService.organization$
			.pipe(takeUntil(this.destroy$))
			.subscribe((organization) => {
				if (organization) {
					this.organization = organization;
				}
			});
	}

	setupNotifications() {
		const notificationId = this.route.snapshot.queryParams['notification'];
		if (notificationId) {
			this.notificationService.findById(notificationId).then((value) => {
				const notification = value?.data();
				if (notification) {
					this.router.navigate(['.']);
					this.onOpenNotification(notification);
				}
			});
		}

		this.notificationService.data.then((data) => {
			this.notifications = data ?? [];
			this.determineUnreadCount();
		});
	}

	/**
	 * Determines what the unread notification count is
	 */
	determineUnreadCount() {
		this.unreadCount = this.notifications.filter((n) => !n.readAt).length;
	}

	toggleExpanded() {
		this.expanded = !this.expanded;
		localStorage.setItem('expanded', String(this.expanded));
	}

	onContactSupport() {
		window.open('mailto:support@arrayapp.io');
	}

	onSubmitTicket() {
		window.open('https://support.arrayapp.io', '_blank');
	}

	onLogOut() {
		this.router.navigate(['logout']);
	}

	onSwitchOrganization() {
		const dialog = this.dialog.open(SwitchOrganizationDialogComponent);

		dialog
			.afterClosed()
			.pipe(take(1))
			.subscribe((res: DialogAction) => {
				switch (res?.role) {
					case DialogRole.Submit:
						this.authService.switchToOrganizationById(
							res?.data.organization.id
						);
						break;
				}
			});
	}

	/**
	 * Marks the corresponding notification as read so it won't be shown has a
	 * new notification anymore. It will still show up in the menu.
	 *
	 * @param id the id of the notification
	 * @param readAt the time that the user marked the notification as read
	 */
	async onMarkNotificationAsRead(id: string) {
		const user = this.authService.user$.value;
		if (user) {
			this.notificationRequests.add(id);
			try {
				await this.notificationService.markAsRead(id, Timestamp.now());
				this.notificationRequests.delete(id);
				this.determineUnreadCount();
			} catch (error) {
				console.error(
					'There was an issue marking the notification as read',
					error
				);
				this.notificationRequests.delete(id);
			}
		}
	}

	/**
	 * Deletes the notification with the given id. Deleted notifications
	 * will no longer be shown in the menu.
	 *
	 * @param id the id of the notification
	 */
	async onDeleteNotification(id: string) {
		const user = this.authService.user$.value;
		if (user) {
			this.notificationRequests.add(id);
			try {
				await this.notificationService.delete(id);
				this.notificationRequests.delete(id);
				this.determineUnreadCount();
			} catch (error) {
				console.error(
					'There was an issue deleting the notification',
					error
				);
				this.notificationRequests.delete(id);
			}
		}
	}

	/**
	 * Opens the notification in a focused dialog
	 *
	 * @param notification the notification to view
	 */
	onOpenNotification(notification: Notification) {
		this.dialog.open(NotificationDialogComponent, {
			data: {
				notification
			},
			panelClass: 'notification'
		});
	}
}
