<app-select-wrapper>
	<mat-select
		class="secondary"
		[value]="selectedLanguage.code"
		(selectionChange)="selectLanguage($event)"
	>
		<mat-option *ngFor="let lang of languages" #code [value]="lang.code">
			{{ lang.name }}
		</mat-option>
	</mat-select>
</app-select-wrapper>
