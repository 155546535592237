<app-hex-color
	[color]="value"
	[(colorPicker)]="value"
	[cpEyeDropper]="true"
	(colorPickerChange)="onPickerChange()"
	cpAlphaChannel="disabled"
></app-hex-color>
<input
	placeholder="#ffffff"
	[value]="value"
	type="text"
	(input)="onValueChange($event.target)"
/>
