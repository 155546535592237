import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { SelectWrapperComponent } from '@array-app/frontend/common';
import { Language } from '@array-app/shared/types';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DefaultLanguage, SupportedLanguages } from '../../data';

@Component({
	standalone: true,
	selector: 'app-language-dropdown',
	imports: [
		CommonModule,
		TranslateModule,
		MatSelectModule,
		SelectWrapperComponent
	],
	templateUrl: 'language-dropdown.component.html'
})
export class LanguageDropdownComponent implements OnInit {
	/**
	 * The currently selected language
	 */
	selectedLanguage: Language = DefaultLanguage;
	/**
	 * `true` if the dropdown is open
	 */
	open = false;
	/**
	 * list of supported languages to render
	 */
	readonly supportedLanguages = SupportedLanguages;
	/**
	 * List of currently visible languages. Including currently active search value.
	 */
	languages = this.supportedLanguages;

	constructor(
		private readonly el: ElementRef,
		private readonly translateService: TranslateService
	) {}

	ngOnInit() {
		const code = this.translateService.currentLang;
		this.selectedLanguage =
			SupportedLanguages.find((lang) => lang.code === code) ||
			DefaultLanguage;
	}

	onSearch(event: any) {
		const search = event.target.value?.toLowerCase().trim();
		this.languages = this.supportedLanguages.filter((lang) => {
			return (
				lang.name.toLowerCase().includes(search) ||
				lang.code.toLowerCase().includes(search)
			);
		});
	}

	// selectLanguage(lang: Language) {
	// 	this.selectedLanguage = lang;
	// 	this.open = false;
	// }

	selectLanguage(event: { value: string }) {
		this.selectedLanguage =
			this.supportedLanguages.find((lang) => lang.code === event.value) ||
			DefaultLanguage;
		this.translateService.use(this.selectedLanguage.code);
		localStorage.setItem('lang', this.selectedLanguage.code);
	}

	@HostListener('document:click', ['$event'])
	onClick(event: MouseEvent) {
		if (!this.el.nativeElement.contains(event.target)) {
			this.open = false;
		}
	}
}
