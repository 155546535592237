import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-error-text',
	imports: [CommonModule],
	templateUrl: 'error-text.component.html',
	styleUrls: ['error-text.component.scss']
})
export class ErrorTextComponent {}
