import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef
} from '@angular/material/dialog';
import { AuthService } from '@array-app/frontend/authentication';
import {
	LoadingSpinnerComponent,
	RelativeTimestampPipe
} from '@array-app/frontend/common';
import { Notification } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationItemComponent } from '../../components';
import { NotificationApiService } from '../../services';

@Component({
	standalone: true,
	selector: 'app-notification-dialog',
	imports: [
		TranslateModule,
		MatDialogModule,
		RelativeTimestampPipe,
		NotificationItemComponent,
		CommonModule,
		LoadingSpinnerComponent
	],
	templateUrl: 'notification-dialog.component.html',
	styleUrls: ['notification-dialog.component.scss']
})
export class NotificationDialogComponent {
	busy = false;

	constructor(
		private readonly dialogRef: MatDialogRef<NotificationDialogComponent>,
		private readonly notificationService: NotificationApiService,
		private readonly authService: AuthService,
		@Inject(MAT_DIALOG_DATA)
		public data: { notification: Notification }
	) {}

	onClose() {
		this.dialogRef.close();
	}

	async onMarkAsRead() {
		if (this.busy) {
			return;
		}

		this.busy = true;
		const user = this.authService.user$.value;
		if (user) {
			const now = Timestamp.now();
			await this.notificationService.markAsRead(
				this.data.notification.id,
				now
			);
			// Mocking data for visuals
			this.data.notification.readAt = now;
		}

		this.busy = false;
	}

	async onDelete() {
		if (this.busy) {
			return;
		}

		this.busy = true;
		const user = this.authService.user$.value;
		if (user) {
			await this.notificationService.delete(this.data.notification.id);
			// Mocking data for visuals.
			this.data.notification.deletedAt = Timestamp.now();
		}

		this.busy = false;
	}
}
