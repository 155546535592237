import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Breadcrumb } from '@array-app/shared/types';
import { fullName } from '@array-app/shared/utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'app-breadcrumbs',
	imports: [CommonModule, TranslateModule, RouterModule],
	templateUrl: 'breadcrumbs.component.html',
	styleUrls: ['breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
	breadcrumbs: Breadcrumb[] = [];

	constructor(private readonly route: ActivatedRoute) {}

	ngOnInit() {
		const data = this.route.snapshot.data;
		this.breadcrumbs = (data['breadcrumbs'] || []).map(
			(breadcrumb: Breadcrumb) => {
				switch (breadcrumb.type) {
					case 'user': {
						if (data['user']) {
							return {
								text: fullName(data['user']),
								url: `/app/users/${data['user'].id}`,
								...breadcrumb
							};
						} else {
							console.error('No user supplied to breadcrumbs');
							break;
						}
					}
					case 'organization': {
						if (data['organization']) {
							return {
								text: data['organization'].name,
								url: `/app/organizations/${data['organization'].id}`,
								...breadcrumb
							};
						} else {
							console.error(
								'No organization supplied to breadcrumbs'
							);
							break;
						}
					}
					case 'product': {
						if (data['product']) {
							return {
								text: data['product'].name,
								url: `/app/products/${data['product'].id}`,
								...breadcrumb
							};
						} else {
							console.error('No product supplied to breadcrumbs');
							break;
						}
					}
					case 'tag': {
						if (data['tag']) {
							return {
								text: data['tag'].name,
								url: `/app/tags/${data['tag'].id}`,
								...breadcrumb
							};
						} else {
							console.error('No tag supplied to breadcrumbs');
							break;
						}
					}
					case 'group': {
						if (data['group']) {
							return {
								text: data['group'].name,
								url: `/app/groups/${data['group'].id}`,
								...breadcrumb
							};
						} else {
							console.error('No group supplied to breadcrumbs');
							break;
						}
					}
					case 'report': {
						if (data['report']) {
							return {
								text: data['report'].name,
								url: `/app/reports/${data['report'].id}`,
								...breadcrumb
							};
						} else {
							console.error('No report supplied to breadcrumbs');
							break;
						}
					}
					case 'externalLink': {
						if (data['link']) {
							return {
								text: data['link'].name,
								url: `/app/external-links/${data['link'].id}`,
								...breadcrumb
							};
						} else {
							console.error(
								'No external link supplied to breadcrumbs'
							);
							break;
						}
					}
				}

				return breadcrumb;
			}
		);
	}
}
