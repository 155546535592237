import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { SearchWrapperComponent } from '@array-app/frontend/common';
import { GlobalSearchComponent } from '@array-app/frontend/global-search';
import { NotificationItemComponent } from '@array-app/frontend/notifications';
import {
	OrganizationLogoComponent,
	OrganizationWordmarkComponent,
	SwitchOrganizationDialogComponent
} from '@array-app/frontend/organizations';
import { ResourceListEmptyStateComponent } from '@array-app/frontend/resource-list';
import { TableHeaderComponent } from '@array-app/frontend/tables';
import {
	FullNamePipe,
	IsAdminPipe,
	PermissionAccessPipe,
	UserAvatarComponent
} from '@array-app/frontend/users';
import { TranslateModule } from '@ngx-translate/core';
import { NavTemplateComponent } from './nav-template.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		OrganizationLogoComponent,
		MatMenuModule,
		SwitchOrganizationDialogComponent,
		SearchWrapperComponent,
		OrganizationWordmarkComponent,
		GlobalSearchComponent,
		FullNamePipe,
		IsAdminPipe,
		PermissionAccessPipe,
		UserAvatarComponent,
		ResourceListEmptyStateComponent,
		NotificationItemComponent,
		TableHeaderComponent
	],
	declarations: [NavTemplateComponent],
	exports: [NavTemplateComponent]
})
export class NavTemplateModule {}
