<div class="container">
	<div class="navigation">
		<ng-container *ngIf="breadcrumbs">
			<app-breadcrumbs
				class="margin-left-13 margin-bottom-1"
			></app-breadcrumbs>
		</ng-container>

		<div class="title">
			<ng-container *ngIf="backUrl">
				<button class="icon-only" [routerLink]="[backUrl]">
					<i class="fa-solid fa-arrow-left"></i>
				</button>
			</ng-container>

			<h2><ng-content></ng-content></h2>

			<div class="extras">
				<ng-content select="[extras]"></ng-content>
			</div>
		</div>
	</div>

	<div class="actions">
		<ng-content select="[actions]"></ng-content>
	</div>
</div>
