<div class="global-top-bar">
	<div class="container">
		<app-organization-wordmark
			class="margin-0"
			size="xsmall"
			[organization]="organization"
		></app-organization-wordmark>

		<div class="actions">
			<ng-container *ngIf="user | isAdmin">
				<app-global-search></app-global-search>
			</ng-container>

			<button
				class="icon-only notifications"
				[matMenuTriggerFor]="notificationMenu"
			>
				<ng-container *ngIf="unreadCount > 0">
					<p class="count-indicator">
						<ng-container
							*ngIf="unreadCount <= maxNotificationsCount"
						>
							{{ unreadCount }}
						</ng-container>
						<ng-container
							*ngIf="unreadCount > maxNotificationsCount"
						>
							{{
								'notifications.count-gt'
									| translate
										: { count: maxNotificationsCount }
							}}
						</ng-container>
					</p>
				</ng-container>
				<i class="fa-solid fa-bell"></i>
			</button>

			<app-user-avatar
				size="xsmall"
				[user]="user"
				[loading]="!user"
				tabindex="0"
				[matMenuTriggerFor]="menu"
				(keypress)="$event.stopPropagation()"
			></app-user-avatar>
		</div>
	</div>
</div>
<div class="global-content">
	<div class="side-navigation" [class.expanded]="expanded">
		<div class="content">
			<ng-container *ngFor="let data of menuGroups">
				<div class="nav-group" *ngIf="data.roles.includes(user.role)">
					<ng-container *ngFor="let item of data.items">
						<button
							class="nav-item nav"
							*ngIf="item.permissions ?? [] | permissionAccess"
							routerLinkActive="active"
							[routerLink]="item.url"
							[title]="item.name | translate"
						>
							<i [class]="item.icon"></i>
							<p>
								{{ item.name | translate }}
							</p>
						</button>
					</ng-container>
				</div>
			</ng-container>
		</div>

		<div class="bottom">
			<button
				class="toggle-expand secondary small"
				[class.rotate]="expanded"
				(click)="toggleExpanded()"
			>
				<i class="fa-solid fa-chevron-right xsmall"></i>
			</button>
		</div>
	</div>
	<div class="router-content">
		<router-outlet></router-outlet>
	</div>
</div>

<mat-menu #menu="matMenu">
	<ng-template matMenuContent>
		<button mat-menu-item routerLink="/app/profile">
			<i class="fa-solid fa-user"></i>
			{{ 'route.title.view-profile' | translate }}
		</button>
		<button mat-menu-item (click)="onContactSupport()">
			<i class="fa-solid fa-envelope"></i>
			{{ 'support.contact-support' | translate }}
		</button>
		<button mat-menu-item (click)="onSubmitTicket()">
			<i class="fa-solid fa-ticket"></i>
			{{ 'support.submit-ticket' | translate }}
		</button>
		<button mat-menu-item [routerLink]="'/docs'">
			<i class="fa-solid fa-file"></i>
			{{ 'docs.label' | translate }}
		</button>
		<button
			*ngIf="canSwitchOrganization"
			mat-menu-item
			(click)="onSwitchOrganization()"
		>
			<i class="fa-solid fa-arrow-right-arrow-left"></i>
			{{ 'users.switch-organization' | translate }}
		</button>
		<button class="danger" mat-menu-item (click)="onLogOut()">
			<i class="fa-solid fa-arrow-right-from-bracket"></i>
			{{ 'generic.log-out' | translate }}
		</button>
		<div class="padding-v-1 padding-h-3 full-width">
			<p
				class="xsmall margin-0 text-tertiary text-right"
				[title]="'v' + version"
			>
				v{{ version }}
			</p>
		</div>
	</ng-template>
</mat-menu>

<mat-menu #notificationMenu="matMenu" class="notification-menu">
	<ng-template matMenuContent>
		<app-table-header>
			{{ 'notifications.label.plural' | translate }}
		</app-table-header>

		<div class="content-container" (click)="$event.stopPropagation()">
			<app-resource-list-empty-state
				*ngIf="!notifications.length"
				size="small"
				icon="fa-solid fa-check"
				header="notifications.label.plural"
				subheader="notifications.empty"
			></app-resource-list-empty-state>

			<ng-container *ngIf="notifications.length">
				<ng-container *ngFor="let notification of notifications">
					<app-notification-item
						[notification]="notification"
						[busy]="notificationRequests.has(notification.id)"
						(markAsRead)="onMarkNotificationAsRead(notification.id)"
						(delete)="onDeleteNotification(notification.id)"
					></app-notification-item>
				</ng-container>
			</ng-container>
		</div>
	</ng-template>
</mat-menu>
